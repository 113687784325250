import React, { useEffect, useState } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper, Typography, Checkbox, Button, Chip, Collapse, Box, TextField, Grid2
} from '@mui/material';
import { statusColors } from '../../../config/status';
import { useNavigate } from 'react-router-dom';
import Badge from '@mui/material/Badge';
import MailIcon from '@mui/icons-material/Mail';
import axios from 'axios';
import { getAuth } from 'firebase/auth';
import IconButton from '@mui/material/IconButton';

const PendingCreatorPlacementSummaryTable = ({ placementData, campaignId, existingApplicationData, existingApplicationStatus }) => {
  const navigate = useNavigate();
  const [applicationStatus, setApplicationStatus] = useState(false);
  const [unreadMessages, setUnreadMessages] = useState(0);
  const [creatorId, setCreatorId] = useState('');

 

  // Calculate the total budgeted fee
  const totalFee = placementData.reduce((sum, placement) => {
    const fee = parseFloat(placement.fee.replace(/[^0-9.-]+/g, '')) || 0; // Remove any non-numeric characters
    return sum + fee;
  }, 0);

  const findProposedFee = (placementId) => {
    const placement = existingApplicationData.find(p => p.placementId === placementId);
    return placement ? placement.proposedFee : null;
  };

  const findPlacementStatus = (placementId) => {
    const placement = existingApplicationData.find(p => p.placementId === placementId);
    return placement ? placement.status : null;
  };

  const totalProposedFees = existingApplicationData.reduce((sum, placement) => {
    const proposedFee = parseFloat(placement.proposedFee) || 0; // Ensure the proposedFee is a number
    return sum + proposedFee;
  }, 0);

  const checkUnreadMessages = async () => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      const creatorId = user.uid;
      setCreatorId(creatorId);
      console.log(creatorId);
      if (user) {
      const idToken = await user.getIdToken();
      const response = await axios.post('https://ostendo.nucleotto.com/api/campaign/messages/unread', {
        campaignId: campaignId,
        userId: creatorId,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
      });

      // Assuming response contains number of unread messages
      setUnreadMessages(response.data.unreadCount);
    }}catch (error) {
      console.error('Error fetching unread messages:', error);
    }
  };


  const handleBadgeClick = () => {
    navigate(`/messaging`, {state: {campaignId}}); }

  useEffect(() => {
    const user_type = localStorage.getItem('user_type');
    if (!user_type) {
      navigate('/logout');
    }
    checkUnreadMessages();

  }, [navigate]);

  return (
    <TableContainer component={Paper} sx={{ borderRadius: 4, boxShadow: 2, marginTop: 6 }}>
<Grid2 container justifyContent="flex-end" sx={{marginRight: 4, marginTop: 4}}>
<Grid2 item align="right">
  <IconButton>
  <MailIcon color="action" onClick={handleBadgeClick}/>
 </IconButton>
</Grid2>  
</Grid2>

      <Grid2 container alignItems="center" justifyContent="space-between" sx={{ mt: 2 }}>
        <Typography variant="h6" sx={{ padding: 2, fontWeight: 'bold' }}>
          Media Plan
        </Typography>
        <Typography variant="subtitle1" sx={{ padding: 2, fontWeight: 'bold' }}>
          Proposed: ${totalProposedFees.toFixed(2)} / Budgeted: ${totalFee.toFixed(2)}
        </Typography>
        <Grid2 item>
          <Grid2 container justifyContent="flex-end">
              <Typography variant="h6" sx={{ padding: 2, fontWeight: 'bold', color: 'ORANGE' }}>
      {existingApplicationStatus}

      
        </Typography>
      
          </Grid2>
        </Grid2>
      </Grid2>
     
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <strong></strong>
            </TableCell>
            <TableCell>
              <strong>CAMPAIGN TYPE</strong>
            </TableCell>
            <TableCell>
              <strong>PLATFORM</strong>
            </TableCell>
            <TableCell>
              <strong>FORMAT</strong>
            </TableCell>
            <TableCell>
              <strong>FEE</strong>
            </TableCell>
            <TableCell>
              <strong></strong>
            </TableCell>
            <TableCell>
              <strong>STATUS</strong>
            </TableCell>
            <TableCell>
              <strong></strong>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {placementData.map((row, index) => {
            const proposedFee = findProposedFee(row._id);
            const placementStatus = findPlacementStatus(row._id);
            
    

            return (
              <React.Fragment key={index}>
                <TableRow>
                  {/* Checkbox Cell */}
                  <TableCell>
                    <Checkbox
                      checked={true}
                      inputProps={{ 'aria-label': 'Select Placement' }}
                      disabled={true}
                    />
                  </TableCell>
                  {/* Other Cells */}
                  <TableCell>{row.campaignType}</TableCell>
                  <TableCell>{row.platform}</TableCell>
                  <TableCell>{row.format}</TableCell>
                  <TableCell>{`$${row.fee}`}</TableCell>
                  <TableCell>
                    {proposedFee && proposedFee !== row.fee ? (
                      <Typography variant="body2" color="primary">
                        Proposed: ${proposedFee}
                      </Typography>
                    ) : (
                      <Typography variant="body2" color="textSecondary">
                        No proposed fee
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell>
                    <Chip
                      label={placementStatus}
                      sx={{
                        backgroundColor:
                          statusColors[placementStatus]?.backgroundColor ||
                          statusColors.default.backgroundColor,
                        color:
                          statusColors[placementStatus]?.color ||
                          statusColors.default.color,
                      }}
                    />
                  </TableCell>
                
                 
    
                </TableRow>
              </React.Fragment>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PendingCreatorPlacementSummaryTable;
