import { configureStore } from '@reduxjs/toolkit';
import userSlice, { setUser } from './slices/userSlice';
import { saveState, loadState } from '../../src/utils/localStorage'

const persistedState = loadState();

const store = configureStore({
  reducer: {
    user: userSlice
  },
  preloadedState: persistedState,
  devTools: process.env.NODE_ENV !== 'production',  
});

store.subscribe(() => {
    saveState({
      user: store.getState().user, // Save only the user state, or entire store if needed
    });
  });

export default store;