import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  CircularProgress,
  Avatar,
  Chip,
  Grid2,
  FormControl,
  MenuItem, InputLabel, Select
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { statusColors } from '../../../config/status';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';


const CampaignSummaryTable = ({campaignData}) => {
  const navigate = useNavigate();
  const [statusFilter, setStatusFilter] = useState('All');

  // Handle the filter change
  const handleFilterChange = (event) => {
    setStatusFilter(event.target.value);
  };

  // Filter the campaign data based on the selected status
  const filteredCampaigns = statusFilter === 'All' 
    ? campaignData 
    : campaignData.filter((campaign) => campaign.status === statusFilter);

  const handleOnClick = async (row) => {
        navigate(`/campaigns/detail/${row._id}`);
    }

  return (
<Grid2 container width='100%' alignItems="center" justifyContent="space-between">

  
   
    <TableContainer component={Paper} sx={{ borderRadius: 4, boxShadow: 2 }}>
      
      <Table>
        <TableHead>
          <TableRow>
            <TableCell><strong>ADVERTISER</strong></TableCell>
            <TableCell><strong>CAMPAIGN NAME</strong></TableCell>
            <TableCell><strong>START DATE</strong></TableCell>
            <TableCell><strong>END DATE</strong></TableCell>
             <TableCell><strong>STATUS</strong></TableCell>

          </TableRow>
        </TableHead>
        <TableBody>
          {filteredCampaigns.map((row, index) => (
            <TableRow key={index} onClick={() => handleOnClick(row)}>
              <TableCell>{row.advertiserName}</TableCell>
              <TableCell>{row.campaignName}</TableCell>
              <TableCell>{row.startDate}</TableCell>
              <TableCell>{row.endDate}</TableCell>
              <TableCell>
               <Chip label={row.status} 
               sx={{ backgroundColor: statusColors[row.status]?.backgroundColor || statusColors.default.backgroundColor,
                    color: statusColors[row.status]?.color || statusColors.default.color
                }}/>
              </TableCell>
            
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </Grid2>
  );
};

export default CampaignSummaryTable;
