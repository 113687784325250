import React, { useState } from 'react';
import { Box, Button, TextField, Typography, Checkbox, FormControlLabel, Avatar, Grid2, Divider, Container, Paper,IconButton, } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const RegisterPage = () => {
  const navigate = useNavigate();
 

  const handleLoginClick = async (e) => {
    try {
     navigate('/login');
   } catch (err) {
     console.log(err);
   }
 };

 const handleAccountTypeSelect = async (type) => {
  try {
    localStorage.setItem('type', type);
    if (type === 'Creator') {
    navigate('/register/select-social')  
    };
    if (type === 'Brand') {
      navigate('/register/select-social')  
    };
    if (type === 'Ad Agency') {
      navigate('/register/select-social')  
    };
      if (type === 'Agent') {
      navigate('/register/select-social')  
    };
  } catch (err) {
    console.log(err)
  }
};


 return (
  <Container maxWidth="lg" sx={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <Grid2 container spacing={4}>
      <Box maxWidth='400px' sx={{p: 5}}>
      {/* Left Section */}
      <Grid2 item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant="subtitle1" sx={{ mb: 1 }}>
          Step 1
        </Typography>
        <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 2 }}>
          Which of these best describe you
        </Typography>
        <Typography variant="body1" sx={{ color: 'text.secondary', mb: 4 }}>
         'insert more text here'
        </Typography>
      </Grid2>
      </Box>
      <Box maxWidth='400px' sx={{p: 3, background: '#e6e6e6'}}>
      {/* Right Section */}
      <Grid2 item xs={12} md={8}>
        {/* Option Cards */}
        <Grid2 container spacing={3} p={1}>
        <Grid2 item xs={12} sm={6} md={6}>
          <Paper elevation={2} onClick={() => handleAccountTypeSelect('Brand')} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center',  p: 2, height: '100%', }}>
              <Box><Typography variant="h6" sx={{ fontWeight: 'bold' }}>Brand</Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>Some words here to describe why I'm here</Typography></Box>
              <IconButton><AccountCircleIcon sx={{ fontSize: 40 }} /></IconButton>
          </Paper>
          </Grid2>
          </Grid2>

          <Grid2 container spacing={3} p={1}>
          <Grid2 item xs={12} sm={6} md={6}>
          <Paper elevation={2} onClick={() => handleAccountTypeSelect('Ad Agency')} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center',  p: 2, height: '100%', }}>
              <Box><Typography variant="h6" sx={{ fontWeight: 'bold' }}>Ad Agency</Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>Some words here to describe why I'm here</Typography></Box>
              <IconButton><AccountCircleIcon sx={{ fontSize: 40 }} /></IconButton>
          </Paper>
          </Grid2>
          </Grid2>

          <Grid2 container spacing={3} p={1}>
          <Grid2 item xs={12} sm={6} md={6}>
          <Paper elevation={2} onClick={() => handleAccountTypeSelect('Creator')} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center',  p: 2, height: '100%', }}>
              <Box><Typography variant="h6" sx={{ fontWeight: 'bold' }}>Creator</Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>Some words here to describe why I'm here</Typography></Box>
              <IconButton><AccountCircleIcon sx={{ fontSize: 40 }} /></IconButton>
          </Paper>
          </Grid2>
          </Grid2>

          <Grid2 container spacing={3} p={1}>
          <Grid2 item xs={12} sm={6} md={6}>
          <Paper elevation={2} onClick={() => handleAccountTypeSelect('Agent')} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center',  p: 2, height: '100%', }}>
              <Box><Typography variant="h6" sx={{ fontWeight: 'bold' }}>Agent</Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>Some words here to describe why I'm here</Typography></Box>
              <IconButton><AccountCircleIcon sx={{ fontSize: 40 }} /></IconButton>
          </Paper>
          </Grid2>
          </Grid2>


          
      </Grid2></Box>
    </Grid2>
  </Container>
);
};


export default RegisterPage;
