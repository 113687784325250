import React from 'react';
import {  Table,  TableBody,  TableCell,  TableContainer,  TableHead,  TableRow,  Paper,  Typography,  Box,  CircularProgress,  Avatar,  Chip, Button, Grid2} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { statusColors } from '../../../config/status';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import axios from 'axios';
import { useState } from 'react';


const PlacementSummaryTable = ({placementData, campaignId, campaignDetails}) => {
  const navigate = useNavigate();
  console.log(placementData.length);

  const handleOnClick = async (row) => {
       // navigate(`/campaigns/placement/detail/${row._id}`);
    }

useEffect(() => {
 const user_type = localStorage.getItem('user_type');
 if (!user_type) {
    navigate('/logout')
    }
    
    }, [navigate]);



  const handleEditMediaPlan = async (row) => {
    navigate(`/campaigns/edit/${campaignId}`, {state: { campaignDetails, activeTab: 1,}})
    }
   



  return (
    <TableContainer component={Paper} sx={{ borderRadius: 4, boxShadow: 2, marginTop: 6 }}>
         <Grid2 container alignItems="center" justifyContent="space-between" sx={{ mt: 2 }}>
      <Typography variant="h6" sx={{ padding: 2, fontWeight: 'bold'  }}>
        Media Plan
      </Typography>
      <Grid2 item>
      <Grid2 container justifyContent="flex-end">
      <Button variant="contained" onClick={handleEditMediaPlan} sx={{ borderRadius: '10px', mr: 2 }}>Edit</Button>
      </Grid2></Grid2></Grid2>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell><strong>CAMPAIGN TYPE</strong></TableCell>
            <TableCell><strong>PLATFORM</strong></TableCell>
            <TableCell><strong>FORMAT</strong></TableCell>
            <TableCell><strong>FEE</strong></TableCell>
            <TableCell><strong>STATUS</strong></TableCell>
      
          </TableRow>
        </TableHead>
        <TableBody>
  
          {placementData.map((row, index) => (
            <TableRow key={index} onClick={() => handleOnClick(row)}>
              <TableCell>{row.campaignType}</TableCell>
              <TableCell>{row.platform}</TableCell>
              <TableCell>{row.format}</TableCell>
              <TableCell>{row.fee}</TableCell>
              <TableCell>
               <Chip label={row.status} 
               sx={{ backgroundColor: statusColors[row.status]?.backgroundColor || statusColors.default.backgroundColor,
                    color: statusColors[row.status]?.color || statusColors.default.color
                }}/>
              </TableCell>
             
            </TableRow>
          ))}
          
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PlacementSummaryTable;
