// utils/localStorage.js
export const saveState = (state) => {
    try {
      const serializedState = JSON.stringify(state);
      localStorage.setItem('reduxState', serializedState); // Save state to localStorage
    } catch (error) {
      console.error('Could not save state', error);
    }
  };
  
  export const loadState = () => {
    try {
      const serializedState = localStorage.getItem('reduxState');
      if (serializedState === null) {
        return undefined; // Return undefined if no state is found
      }
      return JSON.parse(serializedState); // Parse and return saved state
    } catch (error) {
      console.error('Could not load state', error);
      return undefined;
    }
  };
  