import React, { useState } from 'react';
import { Box, Button, TextField, Typography, Checkbox, FormControlLabel, Avatar, Grid2, Divider, Container, Paper,IconButton, } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import { getAuth, signInWithPopup, FacebookAuthProvider, fetchSignInMethodsForEmail, GoogleAuthProvider, linkWithCredential } from "firebase/auth";
import { registerUser } from '../../api/userApi';
import { googleProvider } from '../../config/firebase';

const PrimarySocialSelect = () => {
  const navigate = useNavigate();
  const auth = getAuth();
  const facebookProvider = new FacebookAuthProvider();


const handleLoginClick = async (e) => {
    try {
     navigate('/login');
   } catch (err) {
     console.log(err);
   }
 };

 const handleAccountTypeSelect = async (accountType) => {
  try {
    localStorage.setItem('accountType', accountType);
    if (accountType === 'Facebook') {
    await registerWithFacebook();  
    };
    if (accountType === 'Brand') {
      navigate('/register/select-social')  
    };
    if (accountType === 'Ad Agency') {
      navigate('/register/select-social')  
      }
    if (accountType === 'Agent') {
        navigate('/register/select-social')  
        }
  } catch (err) {
    console.log(err)
  }
};

const registerWithFacebook = async () => {
  try {
    // Sign in with Facebook
    const result = await signInWithPopup(auth, facebookProvider);
    console.log(result);
    
    // Corrected: Extract the user info directly from result
    const { user } = result;
    const type = localStorage.getItem('type');
    
    // Save user info to localStorage
    localStorage.setItem('user', JSON.stringify(user));
    
    // Register the user in your backend
    const registerUserRecord = await registerUser(user, type); // Removed JSON.stringify here
    console.log(registerUserRecord);
    localStorage.setItem('recordId', registerUserRecord.result.insertedId)
    // Navigate to the next page
    navigate("/register/confirm-primary");
  } catch (err) {
    console.log(err);
    // Optionally set an error message
  }
};




 return (
  <Container maxWidth="lg" sx={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <Grid2 container spacing={4}>
      <Box maxWidth='400px' sx={{p: 5}}>
      {/* Left Section */}
      <Grid2 item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant="subtitle1" sx={{ mb: 1 }}>
          Step 2
        </Typography>
        <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 2 }}>
          Which is your primary social account?
        </Typography>
        <Typography variant="body1" sx={{ color: 'text.secondary', mb: 4 }}>
         'insert more text here'
        </Typography>
      </Grid2>
      </Box>
      <Box maxWidth='400px' sx={{p: 3, background: '#e6e6e6'}}>
      {/* Right Section */}
      <Grid2 item xs={12} md={8}>
        {/* Option Cards */}
        <Grid2 container spacing={3} p={1}>
        <Grid2 item xs={12} sm={6} md={6}>
          <Paper elevation={2} onClick={() => handleAccountTypeSelect('Facebook')} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center',  p: 2, height: '100%', }}>
              <Box><Typography variant="h6" sx={{ fontWeight: 'bold' }}>Facebook</Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>Some words here to describe why I'm here</Typography></Box>
              <IconButton><FacebookIcon sx={{ fontSize: 40 }} /></IconButton>
          </Paper>
          </Grid2>
          </Grid2>

          <Grid2 container spacing={3} p={1}>
          <Grid2 item xs={12} sm={6} md={6}>
          <Paper elevation={2} onClick={() => handleAccountTypeSelect('Ad Agency')} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center',  p: 2, height: '100%', }}>
              <Box><Typography variant="h6" sx={{ fontWeight: 'bold' }}>Instagram</Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>Some words here to describe why I'm here</Typography></Box>
              <IconButton><InstagramIcon sx={{ fontSize: 40 }} /></IconButton>
          </Paper>
          </Grid2>
          </Grid2>

          <Grid2 container spacing={3} p={1}>
          <Grid2 item xs={12} sm={6} md={6}>
          <Paper elevation={2} onClick={() => handleAccountTypeSelect('Creator')} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center',  p: 2, height: '100%', }}>
              <Box><Typography variant="h6" sx={{ fontWeight: 'bold' }}>TikTok</Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>Some words here to describe why I'm here</Typography></Box>
              <IconButton><AccountCircleIcon sx={{ fontSize: 40 }} /></IconButton>
          </Paper>
          </Grid2>
          </Grid2>

          <Grid2 container spacing={3} p={1}>
          <Grid2 item xs={12} sm={6} md={6}>
          <Paper elevation={2} onClick={() => handleAccountTypeSelect('Agent')} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center',  p: 2, height: '100%', }}>
              <Box><Typography variant="h6" sx={{ fontWeight: 'bold' }}>Youtube</Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>Some words here to describe why I'm here</Typography></Box>
              <IconButton><AccountCircleIcon sx={{ fontSize: 40 }} /></IconButton>
          </Paper>
          </Grid2>
          </Grid2>


          
      </Grid2></Box>
    </Grid2>
  </Container>
);
};

export default PrimarySocialSelect;
