import React, { useState } from 'react';
import { Box, Button, TextField, Typography, Checkbox, FormControlLabel, Avatar, Grid2, Divider, Container, CircularProgress } from '@mui/material';
import { signInWithEmailAndPassword, FacebookAuthProvider, signInWithPopup } from 'firebase/auth';
import { auth, googleProvider } from '../../config/firebase'; // Import Firebase configuration
import AppleIcon from '@mui/icons-material/Apple';
import GoogleIcon from '@mui/icons-material/Google';
import FacebookIcon from '@mui/icons-material/Facebook';
import { useNavigate } from 'react-router-dom';
import { getUser } from '../../api/userApi';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [token, setToken] = useState('');
  const [signInLoading, setSignInLoading] = useState(false);
  const facebookProvider = new FacebookAuthProvider();
  const navigate = useNavigate();

  
  // Handle email/password login
  const handleSignIn = async (e) => {
    setSignInLoading(true);
    try {
      e.preventDefault();
      const signIn = await signInWithEmailAndPassword(auth, email, password);
      const userId = signIn.user.uid;
      console.dir(signIn, { depth: null });
      localStorage.setItem('userId', userId)
      localStorage.setItem('email', email)

      const userRecord = await getUser(userId);
      const user_type = userRecord.result.user_type;
      localStorage.setItem('user_type', user_type);
      if (user_type === 'admin') {
      navigate('/campaigns');
      } else if (user_type === 'creator') {
        navigate('/creators/dashboard')
      }
    } catch (err) {
      console.log(err);
      setSignInLoading(false);
      setError('Invalid login credentials. Please try again.');
    }
  };

  // Handle Google sign-in
  const handleGoogleSignIn = async (e) => {
    e.preventDefault();
    setSignInLoading(true);
    try {
      const signIn = await signInWithPopup(auth, googleProvider);
      const userId = signIn.user.uid;
      console.dir(signIn, { depth: null });
      localStorage.setItem('userId', userId)
      localStorage.setItem('email', signIn.user.email)
      localStorage.setItem('display_name', signIn.user.displayName)
      const userRecord = await getUser(userId);
      const user_type = userRecord.result.user_type;
      localStorage.setItem('user_type', user_type);
      if (user_type === 'admin') {
      navigate('/campaigns');
      } else if (user_type === 'creator') {
        navigate('/creators/dashboard')
      }
    } catch (err) {
      console.log(err);
      setSignInLoading(false);
      setError('Google Sign-In failed. Please try again.');
    }
  };

  const handleFacebookSignIn = async (e) => {
    setSignInLoading(true);
    e.preventDefault();
    try {
      const signIn = await signInWithPopup(auth, facebookProvider);
      const userId = signIn.user.uid;
      console.dir(signIn, { depth: null });
      localStorage.setItem('userId', userId)
      localStorage.setItem('email', signIn.user.email)
      localStorage.setItem('display_name', signIn.user.displayName)
 
      
      const userRecord = await getUser(userId);
      const user_type = userRecord.result.user_type;
      localStorage.setItem('user_type', user_type);
      if (user_type === 'admin') {
      navigate('/campaigns');
      } else if (user_type === 'creator') {
        navigate('/creators/dashboard')
      }
    } catch (err) {
      console.log(err);
      setSignInLoading(false);
      setError('Facebook Sign-In failed. Please try again.');
    } 
  };


  const handleSignUpClick = async (e) => {
     try {
      navigate('/register');
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Container align="center">
        {signInLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', mt: 20 }}>
          <CircularProgress /> {/* Circular loader centered */}
        </Box>
      ) : (
        <>
       
       <Grid2 container sx={{ 
      height: '100vh', 
      width: { xs: '98%', md: '75%', xl: '55%' },
      display: 'flex',
      alignItems: 'center', // Center items vertically
      justifyContent: 'center' // Center items horizontally
    }}>
      <Grid2 item align='center' xs={12} md={6} sx={{ p: 5, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
       
       
        <Box sx={{ mb: 5 }}>
          
        <Typography variant="h3" sx={{ fontWeight: 'bold', mb: 3 }}>CrowdSwell</Typography>
        
          <Typography variant="body1" sx={{ mt: 2 }}>
            Developer Version 1.1
          </Typography>
        </Box>

        {/* Social Sign-In Buttons */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
          
          <Button
            variant="outlined"
            startIcon={<GoogleIcon />}
            fullWidth
            sx={{ ml: 1, py: 1.5 }}
            onClick={handleGoogleSignIn}
          >
            Signin With Google
          </Button>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
          
          <Button
            variant="outlined"
            startIcon={<FacebookIcon />}
            fullWidth
            sx={{ ml: 1, py: 1.5 }}
            onClick={handleFacebookSignIn}
          >
            Signin With Facebook
          </Button>
        </Box>

        <Divider sx={{ my: 2 }}>OR</Divider>

        {/* Email/Password Sign-In */}
        <Box>
          <TextField
            fullWidth
            label="Email"
            type="email"
            variant="outlined"
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            fullWidth
            label="Password"
            type="password"
            variant="outlined"
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          {error && (
            <Typography color="error" sx={{ mt: 2 }}>
              {error}
            </Typography>
          )}

          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
            <FormControlLabel control={<Checkbox />} label="Remember Me" />
            <Button href="#" color="primary">
              Forget Password?
            </Button>
          </Box>

          <Button
            variant="contained"
            fullWidth
            sx={{ mt: 3, py: 1.5 }}
            onClick={handleSignIn}
          >
            Sign in
          </Button>
        </Box>

        <Typography variant="body2" sx={{ mt: 2 }}>
          Don’t have an account? <Button onClick={handleSignUpClick}>Sign Up</Button>
        </Typography>
      </Grid2>
    </Grid2> 
  </>
      )};
      </Container>
   
  )
};

export default LoginPage;
