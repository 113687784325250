import React, { useState, useRef } from 'react';
import {
  Box,
  Container,
  Typography,
  Button,
  Grid2,
  TextField,
  Checkbox,
  FormControlLabel,
  Tab,
  Tabs,
  FormControl,
  RadioGroup,
  Radio,
  FormLabel, MenuItem, Select


  } from '@mui/material';
import Sidebar from '../../components/navigation/MainNav';
import SettingsIcon from '@mui/icons-material/Settings';
import Avatar from '@mui/material/Avatar';
import SearchBar from '../../components/widgets/dashboard/SearchBar';
import { createCampaign } from '../../api/campaignsApi';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';

// Upon creation can set budget per creator OR allow offers (tick box) -
// Campaign deliverables will need to be adjusted.
// Fix image uploads - to blob and return image.
// Finish field uploads


const CreateCampaignTest = () => {
const user = useSelector((state) => state.user.email);
const [blobUrl, setBlobUrl] = useState('');
const [isSubmitting, setIsSubmitting] = useState(false);
const accessToken = useSelector((state) => state.user.accessToken)
const user_type = localStorage.getItem('user_type');
const [imageUploadLoding, setImageUploadLoading] = useState(false);
const now = new Date();
const navigate = useNavigate();
const [campaignData, setCampaignData] = useState({
    advertiserName: '',
    campaignName: '',
    jobType: {
      influencers: false,
      wordOfMouth: false,
      ugcAds: false,
      tvcAds: false,
    },
    remuneration: {
      influencerPayment: false,
      gifting: false,
      influencerPaymentAndGifting: false,
    },
    campaignSummary: '',
    campaignDescription: '',
    websiteLink: '',
    startDate: '',
    endDate: '',
    contentSubmittedBy: '',
    productInformation: '',
    productDelivery: '',
    contentExpectations: {
      influencerChannels: false,
      brandedCreativeAssets: false,
      repurposedByBrand: false,
      brandChannels: false,
      boostedOnBrandChannels: false,
      boostedOnInfluencerChannels: false, 
    },
    useagePeriodPerAsset: '',
    boostingPeriodPerAsset:'',
    usageDetails: '',
    contentPreApproval: '',
    campaignDeliverables: {
      instagram: {
        reel: false,
        inFeedImage: false,
        carousel: false,
        frameStorySet: false
      },
      tiktok: {
        reel: false,
        inFeedVideo: false,
        stitchVideo: false,
        slideShowImageVideo: false
      },
      facebook: {
        reel: false,
        inFeedImage: false,
        carousel: false,
        frameStorySet: false
      },
      youtube: {
        longFormVideo: false,
        shortFormVideo: false,
        
      },
    },
    influencerType: '',
    targetAudiences: '',
    targetAge: '',
    ugcContentPackages: '',
    ugcCreatorExperience: '',
    influencerSize: '',
    influencerGender: '',
            influencerCategoy: '',
            influencerLocation: '',
            influencerAge: '',
            audienceLocation: '',
            audienceGender: '',
            audienceAge: '',
            tvcCtvContentPackages: '',
            tvcCtvCrew: '',
            applicationCloseDate: '',
            createdBy: user,
            createdDate: now,
            contentSubmittedDate: '',
            image1: '',
            status: 'draft'
  });
  const [file, setFile] = useState(null); // State to store the selected file
  const [errorUploadingFile, setErrorUploadingFile] = useState('');
  const [canNotSubmitMessage, setCanNotSubmitMessage] = useState('');
  const [uploadingFileSuccessMessage, setUploadingFileSuccessMessage] = useState('');
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log(name, value);
    setCampaignData({ ...campaignData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) {
      console.error("No file selected.");
      return;
    }

    setImageUploadLoading(true);
  
    try {
console.log('uploading');
const response =  await axios.post('https://crowdswell.nucleotto.com/api/blob/uploadFile', file, {
  headers: {
    'Content-Type': file.type || 'application/octet-stream',
    'X-Filename': file.name,
  },
});
if (!response.data.url) {
  setErrorUploadingFile('OOPS! we encountered an error, please press upload again.');
} else {
const imageURL = response.data.url;
setBlobUrl(response.data.url);
setUploadingFileSuccessMessage('File successfully uploaded');
setCampaignData((prevData) => ({
  ...prevData,
  image1: imageURL,
}));

}} catch (error) {
console.error('Error uploading file:', error);
} finally {
  setImageUploadLoading(false);
}
};
  
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    console.log(name);
    setCampaignData({ ...campaignData, [name]: checked });
  };

  const handleCheckboxChangeJobType = (event) => {
    const { name, checked } = event.target;
    console.log(name);
    setCampaignData((prevData) => ({
      ...prevData,
      jobType: {
        ...prevData.jobType,
        [name]: checked
      }
    }));
  };

  const handleCheckboxContentExpectations = (event) => {
    const { name, checked } = event.target;
    console.log(name);
    setCampaignData((prevData) => ({
      ...prevData,
      contentExpectations: {
        ...prevData.contentExpectations,
        [name]: checked
      }
    }));
  };

  const handleCheckboxChangeRemuneration = (event) => {
    const { name, checked } = event.target;
    setCampaignData((prevData) => ({
      ...prevData,
      remuneration: {
        ...prevData.remuneration,
        [name]: checked
      }
    }));
  };

  const handleCheckboxChangeCampaignDeliverables = (event) => {
    const { name, checked } = event.target;
    const [platform, deliverable] = name.split(".");

    setCampaignData((prevData) => ({
      ...prevData,
      campaignDeliverables: {
        ...prevData.campaignDeliverables,
        [platform]: {
          ...prevData.campaignDeliverables[platform], // Safely spread the existing platform object
          [deliverable]: checked // Update the specific deliverable
        }
      }
    }));
  };


  const handleSubmit = async (e) => {
    if (!blobUrl){
      setCanNotSubmitMessage('Missing fields: you must upload at least one image');
      return
    }
    setIsSubmitting(true);
    e.preventDefault();
    const submitCampaign = await createCampaign(campaignData); 
    console.log(submitCampaign);
    if (submitCampaign) {
        navigate(`/campaigns/detail/${submitCampaign}`, {state: { jobId: submitCampaign } });
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Sidebar />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Container maxWidth="xxl">
          <Box>
            {/* Header Section */}
            <Grid2 container alignItems="center" justifyContent="space-between" sx={{ mb: 2 }}>
              <Grid2 item xs={12}>
                <SearchBar />
              </Grid2>
              <Grid2 item>
                <Grid2 container alignItems="center" spacing={2}>
                  <Grid2 item>
                    <SettingsIcon />
                  </Grid2>
                  <Grid2 item>
                    <Avatar>JG</Avatar>
                  </Grid2>
                </Grid2>
              </Grid2>
            </Grid2>

            {/* Title and Button */}
            <Grid2 container alignItems="center" justifyContent="space-between" sx={{ mb: 4, mt: 4 }}>
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}></Typography>
              <Button variant="contained" onClick={handleSubmit} sx={{ borderRadius: '10px' }}
               disabled={isSubmitting} // Optionally disable the button while loading
               >
                 {isSubmitting ? 'Saving...' : 'Save Draft'}
                
              </Button>
              
            </Grid2>

        
 <Box sx={{ maxWidth: '100%', margin: 'auto', padding: 3 }}>
 <Typography variant='subtitle2'>{canNotSubmitMessage}</Typography>

      {/* Tabs Navigation */}
      <Tabs value={activeTab} onChange={handleTabChange}>
        <Tab label="Campaign" />
        <Tab label="Product" />
        <Tab label="Assets" />
        <Tab label="Creative / Content" />
        <Tab label="Audience" />
        <Tab label="Deliverables" />
        <Tab label="Remuneration" />
        <Tab label="Influencer Profile" />

      </Tabs>

{activeTab === 0 && (

<TabPanel>
  <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>Deliverables</Typography>

  <Box maxWidth="100%" sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
    {/* Column for Labels */}
    <Grid2 container direction="column" xs={2} spacing={3}>
      <Grid2 item>
        <Typography variant="subtitle1">Campaign Type</Typography>
      </Grid2>
      <Grid2 item>
        <Typography variant="subtitle1">Format</Typography>
      </Grid2>
      <Grid2 item>
        <Typography variant="subtitle1">Resharing</Typography>
      </Grid2>
      <Grid2 item>
        <Typography variant="subtitle1">Boosting</Typography>
      </Grid2>
      <Grid2 item>
        <Typography variant="subtitle1">Usage Period</Typography>
      </Grid2>
      <Grid2 item>
        <Typography variant="subtitle1">Exclusivity Period</Typography>
      </Grid2>
      <Grid2 item>
        <Typography variant="subtitle1">Remuneration</Typography>
      </Grid2>
      <Grid2 item>
        <Typography variant="subtitle1">Fee</Typography>
      </Grid2>
    </Grid2>

    {/* Column for Inputs */}
    <Grid2 container direction="column" xs={10} spacing={3}>
      <Grid2 item>
        <FormControl fullWidth>
          <Select
            name="campaignType"
            value={campaignData.campaignType || ''}
            onChange={handleInputChange}
            displayEmpty
          >
            <MenuItem value="">
              <em>Select Campaign Type</em>
            </MenuItem>
            <MenuItem value="Influencers">Influencers</MenuItem>
            <MenuItem value="Brand">Brand</MenuItem>
            <MenuItem value="UGC Ads">UGC Ads</MenuItem>
            <MenuItem value="TVC Ads">TVC Ads</MenuItem>
          </Select>
        </FormControl>
      </Grid2>
      <Grid2 item>
        <FormControl fullWidth>
          <Select
            name="format"
            value={campaignData.format || ''}
            onChange={handleInputChange}
            displayEmpty
          >
            <MenuItem value="">
              <em>Select Format</em>
            </MenuItem>
            <MenuItem value="Reel">Reel</MenuItem>
            <MenuItem value="Image">Image</MenuItem>
            <MenuItem value="Video">Video</MenuItem>
          </Select>
        </FormControl>
      </Grid2>
      <Grid2 item>
        <FormControl fullWidth>
          <Select
            name="resharing"
            value={campaignData.resharing || ''}
            onChange={handleInputChange}
            displayEmpty
          >
            <MenuItem value="">
              <em>Select Resharing</em>
            </MenuItem>
            <MenuItem value="Yes">Yes</MenuItem>
            <MenuItem value="No">No</MenuItem>
          </Select>
        </FormControl>
      </Grid2>
      <Grid2 item>
        <FormControl fullWidth>
          <Select
            name="boosting"
            value={campaignData.boosting || ''}
            onChange={handleInputChange}
            displayEmpty
          >
            <MenuItem value="">
              <em>Select Boosting Option</em>
            </MenuItem>
            <MenuItem value="Influencers">Influencers</MenuItem>
            <MenuItem value="Brand">Brand</MenuItem>
          </Select>
        </FormControl>
      </Grid2>
      <Grid2 item>
        <FormControl fullWidth>
          <Select
            name="usagePeriod"
            value={campaignData.usagePeriod || ''}
            onChange={handleInputChange}
            displayEmpty
          >
            <MenuItem value="">
              <em>Select Usage Period</em>
            </MenuItem>
            <MenuItem value="1 Month">1 Month</MenuItem>
            <MenuItem value="6 Months">6 Months</MenuItem>
            <MenuItem value="12 Months">12 Months</MenuItem>
          </Select>
        </FormControl>
      </Grid2>
      <Grid2 item>
        <FormControl fullWidth>
          <Select
            name="exclusivityPeriod"
            value={campaignData.exclusivityPeriod || ''}
            onChange={handleInputChange}
            displayEmpty
          >
            <MenuItem value="">
              <em>Select Exclusivity Period</em>
            </MenuItem>
            <MenuItem value="1 Month">1 Month</MenuItem>
            <MenuItem value="6 Months">6 Months</MenuItem>
            <MenuItem value="12 Months">12 Months</MenuItem>
          </Select>
        </FormControl>
      </Grid2>
      <Grid2 item>
        <FormControl fullWidth>
          <Select
            name="remuneration"
            value={campaignData.remuneration || ''}
            onChange={handleInputChange}
            displayEmpty
          >
            <MenuItem value="">
              <em>Select Remuneration Type</em>
            </MenuItem>
            <MenuItem value="Product">Product</MenuItem>
            <MenuItem value="Payment">Payment</MenuItem>
            <MenuItem value="Product & Payment">Product & Payment</MenuItem>
          </Select>
        </FormControl>
      </Grid2>
      <Grid2 item>
        <TextField
          fullWidth
          label="Fee"
          name="fee"
          value={campaignData.fee || ''}
          onChange={handleInputChange}
        />
      </Grid2>
    </Grid2>
  </Box>
</TabPanel>

      )}
  


{activeTab === 1 && (
        <TabPanel>
      
          <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>Product</Typography>
          <Box maxWidth='800px'>
          <Grid2 item xs={12} md={8}>
              <TextField fullWidth label="Product Information" name="productInformation" multiline rows={4} value={campaignData.productInformation} onChange={handleInputChange} sx={{marginBottom: 3}}/>
              </Grid2>

          
          <Grid2 item xs={6} sm={3}>
          <FormControl>
          <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 3, mb: 3 }}>Product Delivery</Typography>
          <RadioGroup
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
      >
        <FormControlLabel value="collectProduct" control={<Radio />} label="Creator will need to collect from a specific location" />
        <FormControlLabel value="deliverProduct" control={<Radio />} label="Creator will be shipped the product" />

      </RadioGroup>
    </FormControl>
         </Grid2>


              </Box>
        </TabPanel>
      )}

{activeTab === 3 && (
        <TabPanel>
    
          <Box maxWidth='800px'>
          <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 3, mb: 3 }}>Content Expectations</Typography>
          <Grid2 item xs={6} sm={3}>
            <FormControlLabel
              control={<Checkbox name="influencerChannels" checked={campaignData.contentExpectations.influencerChannels} onChange={handleCheckboxContentExpectations} />}
              label="Content will be published on Influencer channels"
            />
          </Grid2>
          <Grid2 item xs={6} sm={3}>
            <FormControlLabel
              control={<Checkbox name="brandedCreativeAssets" checked={campaignData.contentExpectations.brandedCreativeAssets} onChange={handleCheckboxContentExpectations} />}
              label="Content will only be used as branded creative assets"
            />
          </Grid2>
          <Grid2 item xs={6} sm={3}>
            <FormControlLabel
              control={<Checkbox name="repurposedByBrand" checked={campaignData.contentExpectations.repurposedByBrand} onChange={handleCheckboxContentExpectations} />}
              label="Content will be repurposed by the Brands"
            />
          </Grid2>
          <Grid2 item xs={6} sm={3}>
            <FormControlLabel
              control={<Checkbox name="brandChannels" checked={campaignData.contentExpectations.brandChannels} onChange={handleCheckboxContentExpectations} />}
              label="Content will be published on Brand Channels"
            />
          </Grid2>
          <Grid2 item xs={6} sm={3}>
            <FormControlLabel
              control={<Checkbox name="boostedOnBrandChannels" checked={campaignData.contentExpectations.boostedOnBrandChannels} onChange={handleCheckboxContentExpectations} />}
              label="Content will be boosted as ads from the Brand Channels"
            />
          </Grid2>
          <Grid2 item xs={6} sm={3}>
            <FormControlLabel
              control={<Checkbox name="boostedOnInfluencerChannels" checked={campaignData.contentExpectations.boostedOnInfluencerChannels} onChange={handleCheckboxContentExpectations} />}
              label="Content will be boosted as ads from the Influencers channels"
            />
          </Grid2>
          <Grid2 item xs={12} md={8}>
              <TextField fullWidth label="Usage Period Per Asset" name="useagePeriodPerAsset" value={campaignData.useagePeriodPerAsset} onChange={handleInputChange} sx={{marginBottom: 3, marginTop: 3}}/>
              </Grid2>
              <Grid2 item xs={12} md={8}>
              <TextField fullWidth label="Boosting Period Per Asset" name="boostingPeriodPerAsset" value={campaignData.boostingPeriodPerAsset} onChange={handleInputChange} sx={{marginBottom: 3}}/>
              </Grid2>
              <Grid2 item xs={12} md={8}>
              <TextField fullWidth label="Usage Details" name="usageDetails" value={campaignData.usageDetails} onChange={handleInputChange} sx={{marginBottom: 3}}/>
              </Grid2>
              <Grid2 item xs={6} sm={3}>
            <FormControlLabel
              control={<Checkbox name="contentPreApproval" checked={campaignData.contentPreApproval} onChange={handleCheckboxChange} />}
              label="Content pre-approval before going live"
            />
          </Grid2>
              


              </Box>
        </TabPanel>
      )}


{activeTab === 5 && (

        <TabPanel>
    
          <Box maxWidth='800px'>
          <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 3 }}>Instagram</Typography>
          <Grid2 item xs={6} sm={3}>
            <FormControlLabel
              control={<Checkbox name="instagram.reel" checked={campaignData.campaignDeliverables.instagram.reel} onChange={handleCheckboxChangeCampaignDeliverables} />}
              label="Reel"
            />
          </Grid2>
          <Grid2 item xs={6} sm={3}>
            <FormControlLabel
              control={<Checkbox name="instagram.inFeedImage" checked={campaignData.campaignDeliverables.instagram.inFeedImage} onChange={handleCheckboxChangeCampaignDeliverables} />}
              label="In Feed Image"
            />
          </Grid2>      
          <Grid2 item xs={6} sm={3}>
            <FormControlLabel
              control={<Checkbox name="instagram.carousel" checked={campaignData.campaignDeliverables.instagram.carousel} onChange={handleCheckboxChangeCampaignDeliverables} />}
              label="Carousel"
            />
          </Grid2>    
          <Grid2 item xs={6} sm={3}>
            <FormControlLabel
              control={<Checkbox name="instagram.frameStorySet" checked={campaignData.campaignDeliverables.instagram.frameStorySet} onChange={handleCheckboxChangeCampaignDeliverables} />}
              label="3x Frame Story Set"
            />
          </Grid2>      

               <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 3 }}>Facebook</Typography>
          <Grid2 item xs={6} sm={3}>
            <FormControlLabel
              control={<Checkbox name="facebook.reel" checked={campaignData.campaignDeliverables.facebook.reel} onChange={handleCheckboxChangeCampaignDeliverables} />}
              label="Reel"
            />
          </Grid2>
          <Grid2 item xs={6} sm={3}>
            <FormControlLabel
              control={<Checkbox name="facebook.inFeedImage" checked={campaignData.campaignDeliverables.facebook.inFeedImage} onChange={handleCheckboxChangeCampaignDeliverables} />}
              label="In Feed Image"
            />
          </Grid2>      
          <Grid2 item xs={6} sm={3}>
            <FormControlLabel
              control={<Checkbox name="facebook.carousel" checked={campaignData.campaignDeliverables.facebook.carousel} onChange={handleCheckboxChangeCampaignDeliverables} />}
              label="Carousel"
            />
          </Grid2>    
          <Grid2 item xs={6} sm={3}>
            <FormControlLabel
              control={<Checkbox name="facebook.frameStorySet" checked={campaignData.campaignDeliverables.facebook.frameStorySet} onChange={handleCheckboxChangeCampaignDeliverables} />}
              label="3x Frame Story Set"
            />
          </Grid2>   

                      <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 3 }}>TikTok</Typography>
          <Grid2 item xs={6} sm={3}>
            <FormControlLabel
              control={<Checkbox name="facebook.reel" checked={campaignData.campaignDeliverables.facebook.reel} onChange={handleCheckboxChangeCampaignDeliverables} />}
              label="Reel"
            />
          </Grid2>
          <Grid2 item xs={6} sm={3}>
            <FormControlLabel
              control={<Checkbox name="facebook.inFeedVideo" checked={campaignData.campaignDeliverables.facebook.inFeedVideo} onChange={handleCheckboxChangeCampaignDeliverables} />}
              label="In Feed Video"
            />
          </Grid2>      
          <Grid2 item xs={6} sm={3}>
            <FormControlLabel
              control={<Checkbox name="facebook.stitchVideo" checked={campaignData.campaignDeliverables.facebook.stitchVideo} onChange={handleCheckboxChangeCampaignDeliverables} />}
              label="Stitch Video"
            />
          </Grid2>    
          <Grid2 item xs={6} sm={3}>
            <FormControlLabel
              control={<Checkbox name="facebook.slideShowImageVideo" checked={campaignData.campaignDeliverables.facebook.slideShowImageVideo} onChange={handleCheckboxChangeCampaignDeliverables} />}
              label="Slide Show Image Video"
            />
          </Grid2>   
          
          <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 3 }}>YouTube</Typography>
          <Grid2 item xs={6} sm={3}>
            <FormControlLabel
              control={<Checkbox name="facebook.shortFormVideo" checked={campaignData.campaignDeliverables.facebook.shortFormVideo} onChange={handleCheckboxChangeCampaignDeliverables} />}
              label="Short Form Video"
            />
          </Grid2>
          <Grid2 item xs={6} sm={3}>
            <FormControlLabel
              control={<Checkbox name="facebook.longFormVideo" checked={campaignData.campaignDeliverables.facebook.longFormVideo} onChange={handleCheckboxChangeCampaignDeliverables} />}
              label="Long Form Video"
            />
          </Grid2>      


              </Box>
        </TabPanel>
      )}



{activeTab === 6 && (
        <TabPanel>
          <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>Remuneration</Typography>

       
        <Grid2 container spacing={2}>
          <Grid2 item xs={6} sm={3}>
            <FormControlLabel
              control={<Checkbox name="influencerPayment" checked={campaignData.remuneration.influencerPayment} onChange={handleCheckboxChangeRemuneration} />}
              label="Influencer Payment"
            />
          </Grid2>
          <Grid2 item xs={6} sm={3}>
            <FormControlLabel
              control={<Checkbox name="gifting" checked={campaignData.remuneration.gifting} onChange={handleCheckboxChangeRemuneration} />}
              label="Influencer Gifting"
            />
          </Grid2>
          <Grid2 item xs={6} sm={3}>
            <FormControlLabel
              control={<Checkbox name="influencerPaymentAndGifting" checked={campaignData.remuneration.influencerPaymentAndGifting} onChange={handleCheckboxChangeRemuneration} />}
              label="Influencer Payment & Gifting"
            />
          </Grid2>
      
        </Grid2>
        </TabPanel>
      )}

{activeTab === 7 && (
        <TabPanel>
          <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>Influencer Profile</Typography>

       
        <Grid2 container spacing={2}>
         
      
        </Grid2>
        </TabPanel>
      )}




  </Box>                  
              
          </Box>
          <Box align="right" marginTop='20px'>
          <Button variant="contained" onClick={handleSubmit} sx={{ borderRadius: '10px' }}
               disabled={isSubmitting} // Optionally disable the button while loading
               >
                 {isSubmitting ? 'Saving...' : 'Save Draft'}
                
              </Button>
              <Typography variant='subtitle2'>{canNotSubmitMessage}</Typography>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

const TabPanel = (props) => {
  const { children } = props;
  return (
    <Box sx={{ paddingTop: 3 }}>
      {children}
    </Box>
  );
};

export default CreateCampaignTest;
