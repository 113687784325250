import React, { useState, useEffect } from 'react';
import { Box, CssBaseline, Container, Typography, Grid2, Skeleton, Avatar, Tabs, Tab } from '@mui/material';
import Sidebar from '../../components/navigation/MainNav';
import SearchBar from '../../components/widgets/dashboard/SearchBar';
import CampaignsOverview from '../../components/widgets/campaigns/CampaignsOverview';
import SettingsIcon from '@mui/icons-material/Settings';
import CardGrid from '../../components/widgets/campaigns/campaignsCards';
import CreateCampaign from '../../components/widgets/buttons/createCampaign';
import CampaignSummaryTable from '../../components/widgets/dashboard/CampaignSummaryTable';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import axios from 'axios';
import ProfileAvatar from '../../components/navigation/profileAvatar';

const CampaignsPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const user_type = localStorage.getItem('user_type');
  const [campaignData, setCampaignData] = useState([]);
  const [filteredCampaigns, setFilteredCampaigns] = useState([]); // Filtered campaigns based on status
  const [selectedStatus, setSelectedStatus] = useState('All'); // Current selected status



  useEffect(() => {
    const user_type = localStorage.getItem('user_type');
    if (!user_type) {
      navigate('/logout')
    }
    getCampaigns();
  }, []);

  const getCampaigns = async () => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        const idToken = await user.getIdToken();
        const response = await axios.post(
          'https://ostendo.nucleotto.com/api/campaign/list',
          {},
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${idToken}`,
            },
          }
        );
        setCampaignData(response.data.jobs || []); // Default to an empty array if no jobs
        setFilteredCampaigns(response.data.jobs || []); // Initialize filtered campaigns

      }
    } catch (error) {
      console.error('Error fetching campaigns:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleStatusChange = (event, newStatus) => {
    setSelectedStatus(newStatus);

    // Filter campaigns based on the selected status
    if (newStatus === 'All') {
      setFilteredCampaigns(campaignData); // Show all campaigns
    } else {
      const filtered = campaignData.filter(campaign => campaign.status === newStatus);
      setFilteredCampaigns(filtered);
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Sidebar />
      <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
        <Container maxWidth="xxl">
          <Box>
            <ProfileAvatar />
            <Grid2 container alignItems="center" justifyContent="space-between" sx={{ mb: 4, mt: 2 }}>
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Campaigns</Typography>
              <CreateCampaign />
            </Grid2>

            <Grid2 container alignItems="center" justifyContent="space-between" sx={{ width: '100%' }}>
              {/* Skeleton loading indicator */}
              {isLoading ? (
                <>
                  <Skeleton variant="rounded" width={300} height={140} /><Skeleton variant="rounded" width={300} height={140} />
                  <Skeleton variant="rounded" width={300} height={140} /><Skeleton variant="rounded" width={300} height={140} />
                </>
              ) : (
                <CampaignsOverview campaignData={campaignData} />
              )}
            </Grid2>
            <Tabs
              value={selectedStatus}
              onChange={handleStatusChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="status filter tabs"
              sx={{ marginBottom: 2, marginTop: 4 }}
            >
              <Tab label="All" value="All" />
              <Tab label="Live" value="Live" />
              <Tab label="Draft" value="Draft" />
              <Tab label="Paused" value="Paused" />
              <Tab label="Completed" value="Completed" />
              <Tab label="Cancelled" value="Cancelled" />
            </Tabs>
            <Grid2 container alignItems="center" justifyContent="space-between" sx={{ mt: 4, width: '100%' }}>
              {isLoading ? (
                <>
                  <Skeleton variant="rounded" width="100%" height={300} />
                </>
              ) : (
                <CampaignSummaryTable campaignData={filteredCampaigns} />
              )}
            </Grid2>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default CampaignsPage;
