import { createSlice } from '@reduxjs/toolkit';

const initialState = {

    initialState: {
      uid: null,
  email: null,
  displayName: null,
  photoURL: null,
  token: null,
  }};
  const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
      setUser: (state, action) => {
        state.uid = action.payload.uid;
        state.email = action.payload.email;
        state.displayName = action.payload.displayName;
        state.photoURL = action.payload.photoURL;
        state.token = action.payload.token;
      },
      clearUser: (state) => {
        state.uid = null;
        state.email = null;
        state.displayName = null;
        state.photoURL = null;
        state.token = null;
      }
    },
  });
  
  export const { setUser, clearUser } = userSlice.actions;
  export default userSlice.reducer;