import React, { useState, useEffect } from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Typography, Box, IconButton, AppBar, Toolbar, CssBaseline } from '@mui/material';
import { Home, AccountBalance } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu'; // Hamburger icon
import { useNavigate, Link, useLocation } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ConnectWithoutContactOutlined } from '@mui/icons-material';

const drawerWidth = 280;

const Sidebar = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const location = useLocation(); // Get the current location
  const user_type = localStorage.getItem('user_type');
  const navigate = useNavigate();
  
  // Check if the screen size is small (for mobile responsiveness)
  const isMobile = useMediaQuery('(max-width:900px)');

  useEffect(() => {
    if (!user_type) {
      navigate('/logout');
    }
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawerContent = (
    <Box>
      <Typography variant="h5" component="div" sx={{ fontWeight: 'bold', mb: 2 }}>
        CrowdSwell
      </Typography>
      <List>
        {[
       ...(user_type === 'creator' ? [{ text: 'Campaigns', icon: <Home />, path: '/creators/dashboard' }] : []),
       ...(user_type === 'admin' ? [{ text: 'Campaigns', icon: <Home />, path: '/campaigns' }] : []),
       
        ].map((item, index) => (
          <ListItem
            button
            key={item.text}
            component={Link}
            to={item.path}
            sx={{
              padding: '12px 16px',
              borderRadius: '10px',
              backgroundColor: location.pathname === item.path ? '#f3f4f6' : 'inherit', // Highlight the active item
              '&:hover': {
                backgroundColor: '#f3f4f6',
              },
            }}
          >
            <ListItemIcon sx={{ color: item.active ? '#1976d2' : '#000' }}>
              {item.icon}
            </ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />

      {/* AppBar for mobile view */}
      {isMobile && (
        <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
          <Toolbar>
            <IconButton
              color="inherit"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              CrowdSwell
            </Typography>
          </Toolbar>
        </AppBar>
      )}

      {/* Permanent drawer for larger screens */}
      <Drawer
        variant={isMobile ? 'temporary' : 'permanent'}
        open={isMobile ? mobileOpen : true}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            padding: '24px',
            backgroundColor: '#fff',
          },
        }}
      >
        {drawerContent}
      </Drawer>

      {/* Spacer for content shift when sidebar is open */}
      {isMobile && <Toolbar />}
    </Box>
  );
};

export default Sidebar;
