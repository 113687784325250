import axios from 'axios';


const BASE_URL = 'https://ostendo.nucleotto.com/api/user'; // move to env


export const getUser = async (userId) => {
    try {
            const response = await axios.get(
            `${BASE_URL}/getUser?userId=${userId}`, 
              {
              headers: {
               
               'Content-Type': 'application/json'
            //    'Authorization': `Bearer ${accessToken}`
              }
            }
          );

          return response.data;
        } catch (error) {
          console.error('Error creating campaigns', error);
          throw error; // Handle errors appropriately
        }
      };


      export const registerUser = async (user, type) => {
        try {
                const response = await axios.post(
                `${BASE_URL}/register`, 
                {
                  user, type
                },
                  {
                  headers: {
                   
                   'Content-Type': 'application/json'
                //    'Authorization': `Bearer ${accessToken}`
                  }
                }
              );
    
              return response.data;
            } catch (error) {
              console.error('Error creating user', error);
              throw error; // Handle errors appropriately
            }
          };

          export const updateUser = async (user, updateData) => {
            try {
                    const response = await axios.post(
                    `${BASE_URL}/updateUser`, 
                    {
                      user, updateData
                    },
                      {
                      headers: {
                       
                       'Content-Type': 'application/json'
                    //    'Authorization': `Bearer ${accessToken}`
                      }
                    }
                  );
        
                  return response.data;
                } catch (error) {
                  console.error('Error updating user', error);
                  throw error; // Handle errors appropriately
                }
              };
    