import React, { useState } from 'react';
import { Box, Button, TextField, Typography, Checkbox,  RadioGroup, FormControl, FormLabel, Radio, FormControlLabel, Avatar, Grid2, Divider, Container, Paper,IconButton, } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import { getAuth, signInWithPopup, FacebookAuthProvider } from 'firebase/auth';
import { registerUser, updateUser } from '../../api/userApi';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditNoteIcon from '@mui/icons-material/EditNote';
import axios from 'axios';

const PrimaryAssets = () => {
  const navigate = useNavigate();
  const auth = getAuth();
  const location = useLocation();
  const [ loadingSubmit, setLoadingSubmit] = useState(false);
  const [ submittingError, setSubmittingError] = useState('');
  const userJSON = localStorage.getItem('user');
  const user = JSON.parse(userJSON);
  console.log(user.displayName);
  const recordId = localStorage.getItem('recordId')
  const [updateData, setUpdateData] = useState({
    mediaKit: '', 
  })
  const [mediaKitFile, setMediaKitFile] = useState(null); // State to store the selected file
  const [imageUploadLoding, setImageUploadLoading] = useState(false);
  const [MediaKitURL, setMediaKitURL] = useState('');
  const now = new Date();
  const type = localStorage.getItem('type');
  




  const handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log(name, value);
    setUpdateData({ ...updateData, [name]: value });
  };


  const handleLoginClick = async (e) => {
    try {
     navigate('/login');
   } catch (err) {
     console.log(err);
   }
 };
 
 const handleEditDetails = async (e) => {
  try {
   navigate('/login');
 } catch (err) {
   console.log(err);
 }
};
 const handleAccountTypeSelect = async (accountType) => {
  try {
    localStorage.setItem('accountType', accountType);
    if (accountType === 'Facebook') {
    await registerWithFacebook();  
    };
    if (accountType === 'Brand') {
      navigate('/register/select-social')  
    };
    if (accountType === 'Ad Agency') {
      navigate('/register/select-social')  
      }
    if (accountType === 'Agent') {
        navigate('/register/select-social')  
        }
  } catch (err) {
    console.log(err)
  }
};

const registerWithFacebook = async () => {
  try {
  } catch (error) {
    console.error("Error during Facebook login:", error);
    // Handle any error that occurs during sign-in
  }
};

const handleConfirm = async () => {
  try {
    setLoadingSubmit(true);
    await updateUser(recordId, updateData);
    navigate('/campaigns/creators');
  } catch (error) {
    console.error("Error during Facebook login:", error);
    setSubmittingError('There has been a problem saving your details. Please contact your CrowdSwell representative')
  } finally {
    setLoadingSubmit(false);
  }
};

const handleMediaKitFileChange = (e) => {
  setMediaKitFile(e.target.files[0]);
};

const handleMediaKitUpload = async () => {
  if (!mediaKitFile) {
    console.error("No file selected.");
    return;
  }

  setImageUploadLoading(true);

  try {
console.log('uploading');
const response =  await axios.post('https://crowdswell.nucleotto.com/api/blob/uploadFile', mediaKitFile, {
headers: {
  'Content-Type': mediaKitFile.type || 'application/octet-stream',
  'X-Filename': mediaKitFile.name,
},
});
const mediaKitURL = response.data.url;
setMediaKitURL(response.data.url);

setUpdateData((prevData) => ({
...prevData,
mediaKit: mediaKitURL,
}));

} catch (error) {
console.error('Error uploading file:', error);
} finally {
  localStorage.setItem('user_type', type)
setImageUploadLoading(false);
}
};

 return (
  <Container maxWidth="lg" sx={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <Grid2 container spacing={4}>
      <Box maxWidth='400px' sx={{p: 5}}>
      {/* Left Section */}
      <Grid2 item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant="subtitle1" sx={{ mb: 1 }}>
          Step 8
        </Typography>
        <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 2 }}>
        Showcase Your Brilliance
        </Typography>
        <Typography variant="body1" sx={{ color: 'text.secondary', mb: 4 }}>
        Upload your media kit, showreel, or any standout assets
        </Typography>
      </Grid2>
      </Box>
      <Box maxWidth='400px' sx={{p: 3, background: '#e6e6e6'}}>
      {/* Right Section */}
      <Grid2 item xs={12} md={8} >
        {/* Option Cards */}
        <Grid2 container spacing={3} p={1} fullWidth>
        <Grid2 item xs={12} sm={6} md={6} >
          <Paper elevation={2} onClick={() => handleAccountTypeSelect('Facebook')} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center',  p: 2, height: '100%', maxWidth: '333px', width: '333px' }}>
              <Box><Typography variant="h6" sx={{ fontWeight: 'bold', mr: 6 }}>{user.displayName}</Typography></Box>
         {/*}     <IconButton><CheckCircleIcon sx={{ fontSize: 40, color: 'green' }} /></IconButton> */}
          </Paper>
          </Grid2>
          </Grid2>

          <Grid2 container spacing={3} p={1}>
          <Grid2 item xs={12} sm={6} md={6}>
          <Paper elevation={2} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center',  p: 2, height: '100%', maxWidth: '333px', width: '333px'}}>
          <Typography variant="h6">Upload a File</Typography>

<input type="file" onChange={handleMediaKitFileChange} />

<Button variant="contained" onClick={handleMediaKitUpload} disabled={!mediaKitFile}
         >
           {imageUploadLoding ? 'Uploading...' : 'Upload'}
          
        </Button>


{/* Display the uploaded file's URL */}
{MediaKitURL && (
  <Box mt={2}>
    <Typography variant="body1">File uploaded to:</Typography>
    <a href={MediaKitURL} target="_blank" rel="noopener noreferrer"> View file </a>
  </Box>
)}
          </Paper>
          </Grid2>
          </Grid2>

         
          <Grid2 container spacing={3} p={1}>
          <Grid2 item xs={12} sm={6} md={6}>
          <Paper elevation={2}  sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center',  p: 2, height: '100%', maxWidth: '333px', width: '333px'}}>
          <Button align="right" variant='contained' onClick={handleConfirm} disabled={loadingSubmit}>{loadingSubmit ? 'Saving...' : 'CONFIRM'}</Button>
          </Paper>
          </Grid2>
          </Grid2>


          
      </Grid2></Box>
    </Grid2>
  </Container>
);
};

export default PrimaryAssets;
