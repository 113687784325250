import axios from 'axios';
import { getAuth } from 'firebase/auth';


const BASE_URL = 'https://ostendo.nucleotto.com/api/campaign'; // move to env


export const createCampaign = async (campaignData) => {
  console.log("CREATECAMPAIGN:", campaignData)  
  try {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        const idToken = await user.getIdToken();
            const response = await axios.post(
            `${BASE_URL}/create`,
            { 
            campaignData
            },
            {
              headers: {
               'content-Type': 'application/json',
               'Authorization': `Bearer ${idToken}`,
              
              }
            }
          );
          console.log(response.data.campaignId)
          return response.data.campaignId;
        }} catch (error) {
          console.error('Error creating campaigns', error);
          throw error; // Handle errors appropriately
        }
      };

      export const updateCampaign = async (jobId, campaignData) => {
        console.log("UPDATECAMPAIGN:", jobId, campaignData)  
        try {
            const auth = getAuth();
            const user = auth.currentUser;
      
            if (user) {
              const idToken = await user.getIdToken();
                  const response = await axios.post(
                  `${BASE_URL}/update`,
                  { 
                  jobId, campaignData
                  },
                  {
                    headers: {
                      'Authorization': `Bearer ${idToken}`,
                      'Content-Type': 'application/json'
                    }
                  }
                );
                console.log(response.data.campaignId)
                return response.data.campaignId;
              }} catch (error) {
                console.error('Error creating campaigns', error);
                throw error; // Handle errors appropriately
              }
            };

      export const countCampaigns = async (accessToken) => {
        try {
            
            const response = await axios.get(
                `${BASE_URL}/count`, 
                {
                  headers: {
                    Authorization: `Bearer ${accessToken}`,
                      'Content-Type': 'application/json'
                  },
                  withCredentials: true,
                }
              );
              console.log(response.data)
              return response.data;
            } catch (error) {
              console.error('Error creating campaigns', error);
              throw error; // Handle errors appropriately
            }
          };


  export const deleteCampaign = async (deleted_by) => {
    try {
      const response = await axios.post(`${BASE_URL}/create`, deleted_by);
      return response.data;
    } catch (error) {
      console.error("Error creating campaigns", error);
      throw error; // Handle errors appropriately
    }
  };

export const getCampaigns = async (accessToken) => {
    try {
        const response = await axios.post(
    `${BASE_URL}/list`,
    {
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    }
  );
  console.log(response.data.jobId)
  return response.data.jobId;
} catch (error) {
  console.error('Error creating campaigns', error);
  throw error; // Handle errors appropriately
}
};


export const getCampaignId = async (jobId) => { //this is not working for some reason
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      console.log('gettingcampaign');

      if (user) {
        const idToken = await user.getIdToken();
      const response = await axios.post(
        `${BASE_URL}/list?jobId=${jobId}`,
        jobId,
          {
            headers: {
              'Authorization': `Bearer ${idToken}`,
   

            }
          }
        );
        console.log(response.data)
        return response.data;
    }} catch (error) {
      console.error("Error getting campaigns", error);
      throw error;
    }
  };
  
