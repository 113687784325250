import React, { useState } from 'react';
import { Box, Button, TextField, Typography, Checkbox,  RadioGroup, FormControl, FormLabel, Radio, FormControlLabel, Avatar, Grid2, Divider, Container, Paper,IconButton, } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import { getAuth, signInWithPopup, FacebookAuthProvider } from 'firebase/auth';
import { registerUser, updateUser } from '../../api/userApi';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditNoteIcon from '@mui/icons-material/EditNote';

const PrimaryStage = () => {
  const navigate = useNavigate();
  const auth = getAuth();
  const location = useLocation();
  const [ loadingSubmit, setLoadingSubmit] = useState(false);
  const [ submittingError, setSubmittingError] = useState('');
  const userJSON = localStorage.getItem('user');
  const user = JSON.parse(userJSON);
  console.log(user.displayName);
  const [bio, setBio] = useState('');
  const [gender, setGender] = useState('');
  const recordId = localStorage.getItem('recordId')
  const [updateData, setUpdateData] = useState({
    level: '', 
  })


  const handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log(name, value);
    setUpdateData({ ...updateData, [name]: value });
  };


  const handleLoginClick = async (e) => {
    try {
     navigate('/login');
   } catch (err) {
     console.log(err);
   }
 };
 
 const handleEditDetails = async (e) => {
  try {
   navigate('/login');
 } catch (err) {
   console.log(err);
 }
};
 const handleAccountTypeSelect = async (accountType) => {
  try {
    localStorage.setItem('accountType', accountType);
    if (accountType === 'Facebook') {
    await registerWithFacebook();  
    };
    if (accountType === 'Brand') {
      navigate('/register/select-social')  
    };
    if (accountType === 'Ad Agency') {
      navigate('/register/select-social')  
      }
    if (accountType === 'Agent') {
        navigate('/register/select-social')  
        }
  } catch (err) {
    console.log(err)
  }
};

const registerWithFacebook = async () => {
  try {
  } catch (error) {
    console.error("Error during Facebook login:", error);
    // Handle any error that occurs during sign-in
  }
};

const handleConfirm = async () => {
  try {
    setLoadingSubmit(true);
    await updateUser(recordId, updateData);
    navigate('/register/assets-primary');
  } catch (error) {
    console.error("Error during Facebook login:", error);
    setSubmittingError('There has been a problem saving your details. Please contact your CrowdSwell representative')
  } finally {
    setLoadingSubmit(false);
  }
};



 return (
  <Container maxWidth="lg" sx={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <Grid2 container spacing={4}>
      <Box maxWidth='400px' sx={{p: 5}}>
      {/* Left Section */}
      <Grid2 item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant="subtitle1" sx={{ mb: 1 }}>
          Step 7
        </Typography>
        <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 2 }}>
        Map Your Creator Journey
        </Typography>
        <Typography variant="body1" sx={{ color: 'text.secondary', mb: 4 }}>
        Select the options that describes you best
        </Typography>
      </Grid2>
      </Box>
      <Box maxWidth='400px' sx={{p: 3, background: '#e6e6e6'}}>
      {/* Right Section */}
      <Grid2 item xs={12} md={8} >
        {/* Option Cards */}
        <Grid2 container spacing={3} p={1} fullWidth>
        <Grid2 item xs={12} sm={6} md={6} >
          <Paper elevation={2} onClick={() => handleAccountTypeSelect('Facebook')} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center',  p: 2, height: '100%', maxWidth: '333px', width: '333px' }}>
              <Box><Typography variant="h6" sx={{ fontWeight: 'bold', mr: 6 }}>{user.displayName}</Typography></Box>
         {/*}     <IconButton><CheckCircleIcon sx={{ fontSize: 40, color: 'green' }} /></IconButton> */}
          </Paper>
          </Grid2>
          </Grid2>

          <Grid2 container spacing={3} p={1}>
          <Grid2 item xs={12} sm={6} md={6}>
          <Paper elevation={2} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center',  p: 2, height: '100%', maxWidth: '333px', width: '333px'}}>
          <FormControl component="fieldset">
        <FormLabel component="legend">Select Your Level</FormLabel>
        <RadioGroup
          aria-label="level"
          name="level"
          value={updateData.level || ''}
          onChange={handleInputChange}
        >
          <FormControlLabel
            value="Just getting started"
            control={<Radio />}
            label="Just getting started"
          />
          <FormControlLabel
            value="This is my side hustle"
            control={<Radio />}
            label="This is my side hustle"
          />
          <FormControlLabel
            value="I'm a full time creator"
            control={<Radio />}
            label="I'm a full time creator."
          />
        </RadioGroup>
      </FormControl>
          </Paper>
          </Grid2>
          </Grid2>

         
          <Grid2 container spacing={3} p={1}>
          <Grid2 item xs={12} sm={6} md={6}>
          <Paper elevation={2}  sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center',  p: 2, height: '100%', maxWidth: '333px', width: '333px'}}>
          <Button align="right" variant='contained' onClick={handleConfirm} disabled={loadingSubmit}>{loadingSubmit ? 'Saving...' : 'CONFIRM'}</Button>
          </Paper>
          </Grid2>
          </Grid2>


          
      </Grid2></Box>
    </Grid2>
  </Container>
);
};

export default PrimaryStage;
