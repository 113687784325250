import React from 'react';
import { Box, CssBaseline, Container, Typography, Button, Grid2, Avatar, Chip, Stack, CircularProgress, TableContainer, TableCell, TableBody, TableRow, TableHead, Table, Paper } from '@mui/material';
import Sidebar from '../../components/navigation/MainNav';
import SearchBar from '../../components/widgets/dashboard/SearchBar';
import SettingsIcon from '@mui/icons-material/Settings';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import axios from 'axios';
import { useState } from 'react';
import { updateCampaign } from '../../api/campaignsApi';
import { useNavigate } from 'react-router-dom';
import { statusColors } from '../../config/status';


// When creator offers an amount - calculate a probability % of success or show an indiciator.
/// In future - can calculate per view worth per user based on social media followers etc.
// Retrieve Image from campaign record / document.
const jobTypeLabels = {
  influencers: "Influencers",
  wordOfMouth: "Word of Mouth",
  ugcAds: "UGC Ads",
  tvcAds: "TVC Ads",
};

const contentExpectationLabels = {
  influencerChannels: "Influencer Channels",
  brandedCreativeAssets: "Branded Creative Assets",
  repurposedByBrand: "Repurposed By Brand",
  brandChannels: "Brand Channels",
  boostedOnBrandChannels: "Boosted On Brand Channels",
  boostedOnInfluencerChannels: "Boosted On Influencer Channels"

};

const remunerationLabels = {
  influencerPayment: 'Influencer Payment',
  gifting: 'Influencer Gifting',
  influencerPaymentAndGifting: 'Influencer Payment & Gifting',
};



const MediaPlan = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isPublishLoading, setIsPublishLoading] = useState(false);
  const [placementData, setPlacementData] = useState([]);
  const user_type = localStorage.getItem('user_type');
  const [campaignDetails, setCampaignDetails] = useState([]);
  const { campaignId } = useParams();
  console.log(campaignId);

  useEffect(() => {
    getPlacements();
    }, []);

  

  const getPlacements = async () => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        const idToken = await user.getIdToken();
        const response = await axios.post(
          'https://ostendo.nucleotto.com/api/campaign/placement/list',
          {campaignId: campaignId},
          {
            headers: {
              'Content-Type': 'application/json',
               Authorization: `Bearer ${idToken}`,
            },
          }
        );
        setPlacementData(response.data.placements || []); // Default to an empty array if no jobs
      }
    } catch (error) {
      console.error('Error fetching campaigns:', error);
    } finally {
      setIsLoading(false);
    }
  };



  const handlePublish = async (e) => {
    setIsPublishLoading(true);
    e.preventDefault();
  
    // Determine the next status based on the current status
    const nextStatus = campaignDetails.status === 'Live' ? 'Paused' : 'Live';
  
    const updatedCampaignData = {
      status: nextStatus
    };
    
  
  
    setIsPublishLoading(false); // Stop loading
  };
  const handleOnClick = async (row) => {
    // navigate(`/campaigns/placement/detail/${row._id}`);
 }



  //can not publish unless all fields have values that are not blank.

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Sidebar />
      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', mt: 20 }}>
          <CircularProgress /> {/* Circular loader centered */}
        </Box>
      ) : (
        <>
          <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <Container maxWidth="xxl">
              <Box>
                {/* Header Section */}
                <Grid2 container alignItems="center" justifyContent="space-between" sx={{ mb: 2 }}>
                  <Grid2 item xs>
                    <SearchBar />
                  </Grid2>
                  <Grid2 item>
                    <Grid2 container alignItems="center" spacing={2}>
                      <Grid2 item>
                        <SettingsIcon />
                      </Grid2>
                      <Grid2 item>
                        <Avatar>JG</Avatar>
                      </Grid2>
                    </Grid2>
                  </Grid2>
                </Grid2>

                {/* Title and Buttons */}
                <Grid2 container alignItems="center" justifyContent="space-between" sx={{ mb: 4, mt: 4 }}>
                  <Typography variant="h6" sx={{ fontWeight: 'bold' }}></Typography>
                  <Grid2 item>
                    <Grid2 container justifyContent="flex-end">
                      <Button variant="contained" sx={{ borderRadius: '10px', mr: 2 }}>Edit</Button>
                      <Button
                        variant="contained"
                        onClick={handlePublish}
                        sx={{ borderRadius: '10px' }}
                        disabled={isPublishLoading}
                      >
                        {isPublishLoading
                          ? 'Processing...'
                          : campaignDetails.status === 'Live'
                            ? 'Pause'
                            : 'Publish'}
                      </Button>
                    </Grid2>
                  </Grid2>
                </Grid2>

                {/* Campaign Name and Advertiser Name */}
                <TableContainer component={Paper} sx={{ borderRadius: 4, boxShadow: 2, marginTop: 6 }}>
      <Typography variant="h6" sx={{ padding: 2, fontWeight: 'bold'  }}>
        Media Plan
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell><strong>CAMPAIGN TYPE</strong></TableCell>
            <TableCell><strong>PLATFORM</strong></TableCell>
            <TableCell><strong>FORMAT</strong></TableCell>
            <TableCell><strong>FEE</strong></TableCell>
            <TableCell><strong>STATUS</strong></TableCell>
      
          </TableRow>
        </TableHead>
        <TableBody>
  
          {placementData.map((row, index) => (
            <TableRow key={index} onClick={() => handleOnClick(row)}>
              <TableCell>{row.campaignType}</TableCell>
              <TableCell>{row.platform}</TableCell>
              <TableCell>{row.format}</TableCell>
              <TableCell>{row.fee}</TableCell>
              <TableCell>
               <Chip label={row.status} 
               sx={{ backgroundColor: statusColors[row.status]?.backgroundColor || statusColors.default.backgroundColor,
                    color: statusColors[row.status]?.color || statusColors.default.color
                }}/>
              </TableCell>
             
            </TableRow>
          ))}
          
        </TableBody>
      </Table>
    </TableContainer>
               </Box>

    
          
            </Container>
          </Box>
        </>
      )}
    </Box>
  );
};

export default MediaPlan;
