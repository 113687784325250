import React, { useState } from 'react';
import { Box, Grid2, Button, Card, CardContent, Chip, Typography } from '@mui/material';
import { statusColors } from '../../../config/status';
import { useNavigate } from 'react-router-dom';

const CardComponent = ({ card }) => {
  const navigate = useNavigate();
  // Handle missing fields by providing default values
  const {
    jobType = '',   
    image1 = '',           // Default value for job type
    advertiserName = 'Unknown Advertiser',   // Default value for advertiser
    campaignName = 'Unnamed Campaign',       // Default value for campaign name
    campaignSummary = 'No summary available',// Default value for campaign summary
    endDate = 'No end date',                 // Default value for end date
    tagColor = 'grey',                        // Default color for the tag
    statusColor = 'grey',
    status = 'draft'                      // Default color for the status
  } = card;


const handleOnClick = async (e) => {
  e.preventDefault();
      navigate(`/creators/detail/${card._id}`);
  }


  return (
    
      <Card
        sx={{
          width: 300,
          height: 550,
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          cursor: 'pointer', // Add cursor pointer for better UX
        }}
        onClick={handleOnClick}
      >
        {/* Image Section */}
        <Box sx={{ width: '100%', height: 300, position: 'relative' }}>
          <img
            src={image1}
            alt="Campaign"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
          {/* Status Chip */}
          <Chip
            label={status}
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
              backgroundColor:
                statusColors[status]?.backgroundColor ||
                statusColors.default.backgroundColor,
              color: statusColors[status]?.color || statusColors.default.color,
            }}
          />
        </Box>
  
        {/* Content Section */}
        <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
          <Typography variant="body2" color="textSecondary">
            {advertiserName}
          </Typography>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            {campaignName}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{ flexGrow: 1, mb: 1 }}
          >
            {campaignSummary}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Ending {endDate}
          </Typography>
        </CardContent>
      </Card>
    );
  };
  

const CardGrid = ({ campaignData }) => {
  const [visibleCards, setVisibleCards] = useState(26);

  // Create an array of visible cards
  const displayedCards = campaignData.slice(0, visibleCards);

  // Handle "View More" button click
  const handleViewMore = () => {
    setVisibleCards((prev) => prev + 9); // Load 9 more cards on each click
  };

  // Determine if there are more cards to load
  const hasMoreCards = visibleCards < campaignData.length;

  return (
    <Box sx={{ width: '100%', p: 3 }}>
      <Grid2 container spacing={3}>
        {displayedCards.map((campaign, index) => (
          <Grid2 item xs={12} sm={6} md={4} key={campaign._id || index}>
            <CardComponent card={campaign} />
          </Grid2>
        ))}
      </Grid2>

      {/* "View More" Button */}
      {hasMoreCards && (
        <Box sx={{ textAlign: 'center', mt: 3 }}>
          <Button variant="contained" onClick={handleViewMore}>
            View More
          </Button>
        </Box>
      )}
    </Box>
  );
};


export default CardGrid;
