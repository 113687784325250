import React from 'react';
import { Box, CssBaseline, Container, Typography, Button, Grid2, Avatar, Chip, Stack, CircularProgress, TableContainer, TableCell, TableBody, TableRow, TableHead, Table, Paper } from '@mui/material';
import Sidebar from '../../components/navigation/MainNav';
import SearchBar from '../../components/widgets/dashboard/SearchBar';
import SettingsIcon from '@mui/icons-material/Settings';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import axios from 'axios';
import { useState } from 'react';
import { updateCampaign } from '../../api/campaignsApi';
import { useNavigate } from 'react-router-dom';
import { statusColors } from '../../config/status';
import { useLocation } from 'react-router-dom';
import ApplicationSummaryTable from '../../components/widgets/applications/ApplicationsSummaryTable';
import ProfileAvatar from '../../components/navigation/profileAvatar';


const CampaignApplications = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { campaignDetails, placementData, applicationData } = location.state || {};
  const [isLoading, setIsLoading] = useState(false);
  const [isPublishLoading, setIsPublishLoading] = useState(false);
  const user_type = localStorage.getItem('user_type');
  const { campaignId } = useParams();
  console.log(campaignId);

  useEffect(() => {
   
    }, []);

  

  const handleOnClick = async (row) => {
    // navigate(`/campaigns/placement/detail/${row._id}`);
 }





  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Sidebar />
      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', mt: 20 }}>
          <CircularProgress /> {/* Circular loader centered */}
        </Box>
      ) : (
        <>
          <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <Container maxWidth="xxl">
              <Box>
              <ProfileAvatar />

                {/* Title and Buttons */}
                <Grid2 container alignItems="center" justifyContent="space-between" sx={{ mb: 4, mt: 4 }}>
                  <Typography variant="h6" sx={{ fontWeight: 'bold' }}></Typography>
                  <Grid2 item>
                    <Grid2 container justifyContent="flex-end">
                      <Button variant="contained" sx={{ borderRadius: '10px', mr: 2 }}>Edit</Button>
                   
                    </Grid2>
                  </Grid2>
                </Grid2>

                {applicationData && applicationData.map((application, index) => (
  <ApplicationSummaryTable 
    key={index} 
    placementData={placementData} 
    initialApplicationData={application} 
    campaignDetails={campaignDetails}
  />
))}
               </Box>

    
          
            </Container>
          </Box>
        </>
      )}
    </Box>
  );
};

export default CampaignApplications;
