import React, { useEffect, useState } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper, Typography, Checkbox, Button, Chip, IconButton, Collapse, Box, TextField, Grid2
} from '@mui/material';
import { statusColors } from '../../../config/status';
import { useNavigate } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { createApplication } from '../../../api/applicationApi';

const CreatorPlacementSummaryTable = ({ placementData, campaignId }) => {
  const navigate = useNavigate();
  const [campaignApplication, setCampaignApplication] = useState([]);
  const [expandedRows, setExpandedRows] = useState({});
  const [placementEdits, setPlacementEdits] = useState({});
  const [submitting, isSubmitting] = useState(false);
  const [applicationSubmitted, setApplicationSubmitted] = useState(false);

  // Handle the submission of the application
  const handleSubmitApplication = async () => {

    // Build the placementsAppliedTo array with placementId and proposedFee
    const placementsAppliedTo = campaignApplication.map((placement) => {
      const placementId = placement._id;
      const proposedFee = placementEdits[placementId]?.proposedFee;
      const status = 'pending';
      
      return {
        placementId: placementId,
        proposedFee: proposedFee ? parseFloat(proposedFee) : undefined,
        status: status,
      };
    });
  
    // Build the applicationData object
    const applicationData = {
      campaignId: campaignId,
      placementsAppliedTo: placementsAppliedTo,
    };
  
    try {
      isSubmitting(true); 
      const applicationId = await createApplication(applicationData);
  
      // Application submitted successfully
      console.log('Application submitted successfully, ID:', applicationId);
      setApplicationSubmitted(true);
      // For example:
      // navigate(`/application/success/${applicationId}`);
    } catch (error) {
      console.error('Error submitting application:', error);
      // Display error message to the user
      // For example:
      // setErrorMessage('There was an error submitting your application. Please try again.');
    } finally {
      isSubmitting(false); 
    }
  };
  

  // Calculate the total budgeted fee
  const totalFee = placementData.reduce((sum, placement) => {
    const fee = parseFloat(placement.fee.replace(/[^0-9.-]+/g, '')) || 0; // Remove any non-numeric characters
    return sum + fee;
  }, 0);



  // Calculate the total proposed fee based on user input
  const proposedFees = campaignApplication.reduce((sum, placement) => {
    const placementId = placement._id;
    const proposedFee = placementEdits[placementId]?.proposedFee;
    const fee = parseFloat(proposedFee) || 0;
    return sum + fee;
  }, 0);

  useEffect(() => {
    const user_type = localStorage.getItem('user_type');
    if (!user_type) {
      navigate('/logout');
    }
  }, [navigate]);

  return (
    <TableContainer component={Paper} sx={{ borderRadius: 4, boxShadow: 2, marginTop: 6 }}>
      <Grid2 container alignItems="center" justifyContent="space-between" sx={{ mt: 2 }}>
        <Typography variant="h6" sx={{ padding: 2, fontWeight: 'bold' }}>
          Media Plan
        </Typography>
        <Typography variant="subtitle1" sx={{ padding: 2, fontWeight: 'bold' }}>
          Proposed: ${proposedFees.toFixed(2)} / Budgeted: ${totalFee.toFixed(2)}
        </Typography>
        <Grid2 item>
          <Grid2 container justifyContent="flex-end">
          {!applicationSubmitted && (
            <Button
              variant="contained"
              onClick={handleSubmitApplication}
              sx={{ borderRadius: '10px', mr: 2 }}
              disabled={campaignApplication.length === 0 }
            >
                           {submitting ? 'Submitting...' : 'Submit Application'}

            </Button>
          )}
           {applicationSubmitted && (
        <Typography variant="h6" sx={{ padding: 2, fontWeight: 'bold', color: 'ORANGE' }}>
          PENDING
        </Typography>
      )}
          </Grid2>
        </Grid2>
      </Grid2>
     
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <strong></strong>
            </TableCell>
            <TableCell>
              <strong>CAMPAIGN TYPE</strong>
            </TableCell>
            <TableCell>
              <strong>PLATFORM</strong>
            </TableCell>
            <TableCell>
              <strong>FORMAT</strong>
            </TableCell>
            <TableCell>
              <strong>FEE</strong>
            </TableCell>
  
            <TableCell>
              <strong></strong>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {placementData.map((row, index) => {
            // Determine if the current row is selected
            const isChecked = campaignApplication.some(
              (placement) => placement._id === row._id
            );

            // Handle checkbox change
            const handleCheckboxChange = (event) => {
              if (event.target.checked) {
                // Add the placement to the campaignApplication array
                setCampaignApplication((prev) => [...prev, row]);
              } else {
                // Remove the placement from the campaignApplication array
                setCampaignApplication((prev) =>
                  prev.filter((placement) => placement._id !== row._id)
                );
                // Also remove any edits associated with this placement
                setPlacementEdits((prevEdits) => {
                  const newEdits = { ...prevEdits };
                  delete newEdits[row._id];
                  return newEdits;
                });
              }
            };

            // Handle proposed fee change
            const handleProposedFeeChange = (rowId, value) => {
              setPlacementEdits((prevEdits) => ({
                ...prevEdits,
                [rowId]: {
                  ...prevEdits[rowId],
                  proposedFee: value,
                },
              }));
            };

            // Handle comments change (if needed)
            const handleCommentsChange = (rowId, value) => {
              setPlacementEdits((prevEdits) => ({
                ...prevEdits,
                [rowId]: {
                  ...prevEdits[rowId],
                  comments: value,
                },
              }));
            };

            // Determine if the current row is expanded
            const isExpanded = expandedRows[row._id] || false;

            // Handle expand/collapse toggle
            const handleExpandClick = () => {
              setExpandedRows((prev) => ({
                ...prev,
                [row._id]: !prev[row._id],
              }));
            };

            return (
              <React.Fragment key={index}>
                <TableRow>
                  {/* Checkbox Cell */}
                  <TableCell>
                    <Checkbox
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                      inputProps={{ 'aria-label': 'Select Placement' }}
                      disabled={applicationSubmitted}
                    />
                  </TableCell>
                  {/* Other Cells */}
                  <TableCell>{row.campaignType}</TableCell>
                  <TableCell>{row.platform}</TableCell>
                  <TableCell>{row.format}</TableCell>
                  <TableCell>{row.fee}</TableCell>
                 
                  {/* Expand/Collapse Button */}
                  <TableCell>
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={handleExpandClick}
                    >
                      {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                  </TableCell>
                </TableRow>
                {/* Collapsible Row */}
                <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                    <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                      <Box margin={1}>
                        <Grid2 container spacing={2}>
                          {/* Left Column */}
                          <Grid2 item xs={12} md={6}>
                            <Typography variant="h6" gutterBottom component="div">
                              Details
                            </Typography>
                            <Typography variant="body1">
                              Resharing: {row.resharing}
                            </Typography>
                            <Typography variant="body1">
                              Boosting: {row.boosting}
                            </Typography>
                            <Typography variant="body1">
                              Usage Period: {row.usagePeriod}
                            </Typography>
                            <Typography variant="body1">
                              Exclusivity Period: {row.exclusivityPeriod}
                            </Typography>
                            <Typography variant="body1">
                              Remuneration: {row.remuneration}
                            </Typography>
                            {/* Add more fields as needed */}
                          </Grid2>
                          {/* Right Column */}
                          <Grid2 item xs={12} md={6}>
                            <TextField
                              label="Proposed Fee"
                              variant="outlined"
                              fullWidth
                              value={placementEdits[row._id]?.proposedFee || ''}
                              onChange={(e) => handleProposedFeeChange(row._id, e.target.value)}
                              inputProps={{ inputMode: 'decimal', pattern: '[0-9]*' }}     
                              disabled={applicationSubmitted} 
                            />
                            <TextField
                              label="Comments"
                              variant="outlined"
                              fullWidth
                              multiline
                              rows={4}
                              value={placementEdits[row._id]?.comments || ''}
                              onChange={(e) => handleCommentsChange(row._id, e.target.value)}
                              sx={{ mt: 2 }}
                              disabled={applicationSubmitted}
                            />
                          </Grid2>
                        </Grid2>
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CreatorPlacementSummaryTable;
