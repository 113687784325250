import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './index.css';
import PrivateRoute from './auth/privateRoute';
import Dashboard from './pages/Dashboard';
import VideoPlayer from './pages/VideoPlayer';
import LoginPage from '../src/auth/login';
import CampaignsPage from './pages/campaigns';
import SocialMediaPage from './pages/SocialMedia';
import TasksPage from './pages/tasks';
import CreateCampaign from './pages/campaigns/create';
import CreateCampaignTest from './pages/campaigns/createtest';
import LogoutPage from './auth/logout';
import CampaignDetail from './pages/campaigns/detail';
import RegisterPage from './auth/register';
import PrimarySocialSelect from './auth/register/primarySocial';
import PrimaryDetailsConfirm from './auth/register/primaryDetails';
import PrimaryContact from './auth/register/primaryContact';
import PrimaryBio from './auth/register/primaryBio';
import PrimaryNiche from './auth/register/primaryNiche';
import PrimaryStage from './auth/register/primaryStage';
import PrimaryAssets from './auth/register/primaryAssets';
import CreatorsCampaignsPage from './pages/creators';
import MediaPlan from './pages/mediaPlan';
import EditCampaign from './pages/campaigns/edit';

import CampaignApplications from './pages/campaigns/applications';
import CreatorCampaignDetail from './pages/creators/detail';
import MessagingComponent from './pages/messaging';
import UserProfilePage from './pages/profile';





function App() {
  return (

    <Router>
      <Routes>
      <Route path="/" element={<LoginPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/register" element={<RegisterPage />} />
      <Route path="/register/select-social" element={<PrimarySocialSelect />} />
      <Route path="/register/confirm-primary" element={<PrimaryDetailsConfirm />} />
      <Route path="/register/niche-primary" element={<PrimaryNiche />} />
      <Route path="/register/contact-primary" element={<PrimaryContact />} />
      <Route path="/register/stage-primary" element={<PrimaryStage />} />
      <Route path="/register/bio-primary" element={<PrimaryBio />} />
      <Route path="/register/assets-primary" element={<PrimaryAssets />} />

        <Route path="/logout" element={<LogoutPage />} />
        <Route path="/campaigns" element={<PrivateRoute><CampaignsPage /></PrivateRoute>} />
        <Route path="/campaigns/detail/:jobId" element={<PrivateRoute><CampaignDetail /></PrivateRoute>} />
        <Route path="/campaigns/create" element={<PrivateRoute><CreateCampaign /></PrivateRoute>} />
        <Route path="/campaigns/edit/:jobId" element={<PrivateRoute><EditCampaign /></PrivateRoute>} />

        <Route path="/campaigns/createtest" element={<PrivateRoute><CreateCampaignTest /></PrivateRoute>} />
        <Route path="/campaigns/placements" element={<PrivateRoute><MediaPlan /></PrivateRoute>} />
        <Route path="/mediaplan/:campaignId" element={<PrivateRoute><MediaPlan /></PrivateRoute>} />
        <Route path="/campaigns/applications" element={<PrivateRoute><CampaignApplications /></PrivateRoute>} />


        <Route path="/creators/dashboard" element={<PrivateRoute><CreatorsCampaignsPage /></PrivateRoute>} />
        <Route path="/creators/detail/:jobId" element={<PrivateRoute><CreatorCampaignDetail /></PrivateRoute>} />

        <Route path="/messaging" element={<PrivateRoute><MessagingComponent /></PrivateRoute>} />
        <Route path="/profile" element={<PrivateRoute><UserProfilePage /></PrivateRoute>} /> 


        <Route path="/social-media" element={<PrivateRoute><SocialMediaPage /></PrivateRoute>} />
        <Route path="/tasks" element={<PrivateRoute><TasksPage /></PrivateRoute>} />
        <Route path="/videoplayer" element={<PrivateRoute><VideoPlayer /></PrivateRoute>} />
  
     </Routes>
        </Router>
   
  );
}

export default App;
